(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name people.directive:personEvents
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="people">
       <file name="index.html">
        <person-events></person-events>
       </file>
     </example>
   *
   */
  angular
    .module('people')
    .directive('personEvents', personEvents);

  function personEvents($stateParams, AddToVehicle) {
    return {
      restrict: 'EA',
      scope: {
        vehiclePeople: '='
      },
      templateUrl: 'people/person-events-directive.tpl.html',
      controllerAs: 'vm',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'personEvents';
        vm.addToVehicle = function (ev) {
          AddToVehicle.add(ev, $stateParams.personId)
        };
      }
    };
  }
}());
